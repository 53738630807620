const MIN_PHONE_NUMBER_LENGTH = 8; // Solomon Islands

const getCleanNumber = (phoneNumber: string) => {
  return phoneNumber ? phoneNumber.replace(/\D/g, "") : "";
};

const isValidPhoneNumber = (phoneNumber: string) => {
  return getCleanNumber(phoneNumber).length >= MIN_PHONE_NUMBER_LENGTH;
};

export { isValidPhoneNumber, getCleanNumber };
