import { css, type Theme } from "@emotion/react";

import { layers } from "@styles";

export const countrySelector = css`
  display: flex;
  margin-top: 0;
`;

export const countryFlag = css`
  flex-direction: column;
  margin-right: 9px;
`;

export const dropDownButton = (theme: Theme) => css`
  padding-bottom: calc(0.35em - 1px);
  padding-bottom: calc(0.375rem - 1px);
  padding-left: 0;
  padding-right: 0;
  height: 2rem;
  align-self: flex-end;
  margin-bottom: 1px;
  background-color: transparent;
  border-color: transparent;
  color: ${theme.text.body};

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    color: ${theme.text.body};
  }

  &[disabled] {
    border: transparent;
    background-color: transparent;
  }
`;

export const dropDown = css`
  display: flex;
  height: 100%;
`;

export const dropDownMenu = css`
  top: 3.1rem;
  max-width: 26rem;
  z-index: ${layers.modalDropdownMax};
`;
