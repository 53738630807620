import { useTranslation } from "react-i18next";

import { Dropdown } from "@aviary/components/Dropdown";
import { DropdownContent } from "@aviary/components/Dropdown/DropdownContent";
import { DropdownItem } from "@aviary/components/Dropdown/DropdownItem";
import { DropdownButton } from "@aviary/components/Dropdown/DropdownTrigger/DropdownButton";
import { l } from "@aviary/locales/i18n";
import type { PopperPositionType } from "@aviary/types/popperPositions";

import { COUNTRIES } from "./Countries";

import * as styles from "./CountrySelector.styles";

interface Props {
  selectedCode: string;
  onSelect: (code: string) => void;
  dropdownPlacement?: PopperPositionType;
  /**
   * Sets as disabled
   * @default false
   */
  disabled?: boolean;
  onFocus?: () => void;
}

const CountrySelector = ({
  selectedCode = "US",
  onSelect,
  dropdownPlacement,
  disabled = false,
  onFocus,
}: Props) => {
  const { t } = useTranslation();

  const getCurrentValue = () => {
    const selectedValue = COUNTRIES.find(({ code }) => code === selectedCode);
    if (selectedValue) {
      return (
        <div css={styles.countrySelector}>
          <div css={styles.countryFlag}>{selectedValue.flag}</div>
          <div> +{selectedValue.dialCode}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <Dropdown
      css={styles.dropDown}
      dropdownPlacement={dropdownPlacement}
      aria-label={t(l.aviary.countrySelector.dropdownLabel)}
      disabled={disabled}
    >
      <DropdownButton
        isOutlined={false}
        isText
        css={styles.dropDownButton}
        aria-label={`${selectedCode} ${t(l.aviary.countrySelector.defaultCountrySelected)}`}
        onFocus={onFocus}
      >
        {getCurrentValue()}
      </DropdownButton>
      <DropdownContent css={styles.dropDownMenu}>
        {COUNTRIES.map(({ flag, name, dialCode, code }) => (
          <DropdownItem
            key={code}
            value={code}
            onSelect={() => onSelect(code)}
            aria-label={`${name} +${dialCode}`}
          >
            {flag} {name} +{dialCode}
          </DropdownItem>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export { CountrySelector };
