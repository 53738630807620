import { css, type Theme } from "@emotion/react";

import { layers } from "@styles";

export const countrySelector = css`
  position: absolute;
  height: 3.65rem;
  padding-left: 1rem;
  z-index: ${layers.zIndexLowForeground};
  padding-bottom: 0.25rem;
`;

export const phoneInput = css`
  display: flex;
`;

export const phoneInputWrapper = (theme: Theme) => css`
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    input {
      background-color: ${theme.input.backgroundHover};
    }
  }
`;

export const inputWrapperOverrides = css`
  padding-left: 7rem;
`;

export const shortCode = css`
  padding-left: 5.5rem;
`;
