import { css, type Theme } from "@emotion/react";

import { layers } from "@styles";

export const countrySelector = css`
  position: absolute;
  height: 3.65rem;
  padding-left: 1rem;
  z-index: ${layers.zIndexLowForeground};
  padding-bottom: 0.25rem;
`;

export const phoneInput = css`
  display: flex;
`;

export const focused = (theme: Theme) => css`
  label {
    font-weight: ${theme.aviaryTypography.h5.fontWeight};
  }
  input {
    border-color: ${theme.primary.borderActive};
  }
`;

export const hovered = (theme: Theme) => css`
  input {
    border-color: ${theme.primary.borderHover};
  }
`;

export const phoneInputWrapper = {
  base: (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    &:hover {
      input {
        border-color: ${theme.primary.borderHover};
      }
    }
  `,
  disabled: (theme: Theme) => css`
    &:hover {
      input {
        border-color: ${theme.disabled.border};
      }
    }
  `,
};

export const inputWrapperOverrides = css`
  padding-left: 7rem;
`;

export const shortCode = css`
  padding-left: 6rem;
`;
